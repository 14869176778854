<template>
  <div class="infomation main_top">
    <div class="container">
    <h1>{{ $t('key134') }}</h1>
    <h2>1.{{ $t('key135') }}</h2>
    <p>1.1 {{ $t('key136') }}
    </p>
    <p>1.2 {{ $t('key137') }}
    </p>
    <p> 1.3 {{ $t('key138') }}
    </p>

    <h2>2. playsavefristofficial</h2>
    <p> 2.1 {{ $t('key139') }}
    </p>
    <p> 2.2 {{ $t('key140') }}
    </p>
    <p> 2.3 {{ $t('key141') }}
    </p>
    <p> 2.4 {{ $t('key142') }}
    </p>
    <p> 2.5 {{ $t('key143') }}
    </p>

    <h2>3. {{ $t('key144') }}</h2>
    <p> 3.1 {{ $t('key145') }}
    </p>
    <p> 3.2 {{ $t('key146') }}
    </p>
    <p>(1) {{ $t('key147') }}</p>
    <p>
      (2) {{ $t('key148') }}
    </p>
    <p>
      (3) {{ $t('key149') }}
    </p>
    <p>
      (4) {{ $t('key150') }}
    </p>
    <p>
      (5) {{ $t('key151') }}
    </p>
    <p> 3.3 {{ $t('key152') }}
    </p>
    <p> 3.4 {{ $t('key153') }}
    </p>
    <p> 3.5 {{ $t('key154') }}
    </p>
    <p> 3.6 {{ $t('key155') }}
    </p>
    <p> 3.7 {{ $t('key156') }}
    </p>
    <p> 3.8 {{ $t('key157') }}
    </p>

    <h2> 4. {{ $t('key158') }}
    </h2>
    <p> 4.1 {{ $t('key159') }}
    </p>
    <p> 4.2 {{ $t('key160') }}
    </p>

    <h2>5. {{ $t('key161') }}</h2>

    <p> 5.1 {{ $t('key162') }}
    </p>

    <p> 5.2. {{ $t('key163') }}
    </p>
    <p>5.3. {{ $t('key164') }}</p>
    <p>(a) {{ $t('key165') }}</p>
    <p>(b) {{ $t('key167') }}</p>
    <p>(c) {{ $t('key168') }}
    </p>
    <p> (d) {{ $t('key169') }}
    </p>
    <p> (e) {{ $t('key170') }}
    </p>
    <p> {{ $t('key171') }}
    </p>
    <p> 5.4 {{ $t('key172') }}
    </p>

    <h2>6. {{ $t('key173') }}</h2>
    <p> 6.1{{ $t('key174') }}
    </p>
    <p> 6.2 {{ $t('key175') }}
    </p>

    <h2>7. {{ $t('key176') }}</h2>
    <p>7.1 {{ $t('key177') }}
    </p>
    <p> 7.2 {{ $t('key178') }}
    </p>

    <h2>8. {{ $t('key179') }}</h2>
    <p> 8.1 {{ $t('key180') }}
    </p>
    <p> 8.2 {{ $t('key181') }}
    </p>
    
    <h2>9. {{ $t('key182') }}</h2>
    <p>9.1 {{ $t('key183') }}t</p>
    <p> {{ $t('key184') }}
    </p>
    <p>9.2 {{ $t('key185') }}</p>
    <p> {{ $t('key186') }}
    </p>
    <p>9.3 {{ $t('key187') }}</p>
    <p>{{ $t('key188') }}
    </p>
    <p>9.4 {{ $t('key190') }}</p>
    <p> {{ $t('key191') }}
    </p>
    <p>9.5 {{ $t('key192') }}</p>
    <p>{{ $t('key193') }}
    </p>

    <h2>10. {{ $t('key194') }}</h2>
    <p> {{ $t('key195') }}
    </p>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>